<!-- eslint-disable -->
<script setup>
import { defineProps, onMounted, ref, defineEmits } from 'vue';
import { pay, cancel } from '@/api';
import InputField from '@/components/Ui/InputField.vue';

const emit = defineEmits(['onStatusChanged']);

const props = defineProps({
  amount: {
    type: Number,
    required: true
  },
  fields: {
    type: Array,
    default: () => []
  },
  status: {
    type: String,
    required: true
  },
  id: {
    type: String,
    required: true
  }
})

const inputValue = (field, value, isValid) => {
  fieldsValue.value[field].isValid = isValid;
  isValidForm.value = !Object.values(fieldsValue.value).find(el => !el.value || !el.isValid);
}

const fieldsValue = ref(null);

const handleCancel = async () => {
  await cancel(props.id);
  emit('onStatusChanged')
}

const handlePay = async () => {
  let form = {};

  if (fieldsValue.value) {
    form = Object.keys(fieldsValue.value).reduce((obj, field) => {
      obj[field] = fieldsValue.value[field].value;

      return obj;
    }, {})
  }

  const url = await pay(props.id, form);

  if (url) {
    await window.location.replace(url);
  }
}

const isValidForm = ref(true)

onMounted(async () => {
  if (props.fields?.length) {
    fieldsValue.value = await props.fields.reduce((obj, field) => {

      obj[field] = {
        value: '',
        isValid: false
      }

      return obj;
    }, {})

    isValidForm.value = false
  }
})
</script>

<template>
  <div class="details">
    <h1 class="details__header">{{ 'Деталі операції' }}</h1>

    <div class="details__des-wrp">
      <p class="details__des">{{ 'Сума вашого платежа становить:' }}</p>

      <p class="details__amount">
        {{ `${(amount / 100).toFixed(2)} UAH` }}
      </p>
    </div>

    <div v-if="fields?.length && fieldsValue" class="details__fields">
      <div
          v-for="field in fields"
          :key="field"
          class="details__field"
      >
        <InputField
            :input-type="field"
            v-model="fieldsValue[field].value"
            @on-input-value="inputValue"
        />
      </div>
    </div>

    <div class="details__buttons">
      <button
        class="button"
        @click.prevent="handlePay"
        :disabled="!isValidForm"
      >
        {{ 'Оплатити' }}
      </button>

      <button
        class="button-alt"
        @click.prevent="handleCancel"
      >
        {{ 'Відхилити' }}
      </button>
    </div>
  </div>
</template>

<style scoped>
.details__fields {
  width: 100%;
}

.details__field {
  margin-bottom: 10px;
}

.details {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.details__header {
  font-weight: 700;
  font-size: 24px;
  color: #111111;

  @media (max-width: 928px) {
    font-size: 20px;
  }
}

.details__des-wrp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 80px 0 20px;
}

.details__des {
  color: #111111;
  font-size: 16px;
  font-weight: 400;

  @media (max-width: 928px) {
    font-size: 18px;
  }
}

.details__amount {
  color: #111111;
  font-size: 20px;
  font-weight: 800;
}

.details__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  @media (max-width: 928px) {
    flex-direction: column;
    gap: 20px;
  }
}

.button {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 172px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  color: #F8F8F8;
  background-color: #111111;
  margin-right: 24px;

  &:hover, &:active, &:focus {
    background-color: #2E2E2E;
  }

  @media (max-width: 928px) {
    width: 100%;
    margin: 0;
  }

  &:disabled {
    background-color: #a4a4a4;
  }
}

.button-alt {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 172px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  color: #111111;
  border: 1px solid #111111;

  &:hover, &:active, &:focus {
    background-color: #111111;
    color: #F8F8F8;
  }

  @media (max-width: 928px) {
    width: 100%;
  }
}
</style>