import axios from 'axios'

const API_URL = process.env.VUE_APP_API_URL || ''

const api = axios.create({
  baseURL: API_URL,
  mode: 'no-cors',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
})

export default api