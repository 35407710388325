<!-- eslint-disable -->
<script setup>
import {defineProps, ref, watch} from "vue";

const props = defineProps({
  redirect_url: {
    type: String,
    required: true
  }
})


import failed_icon from '../../public/failed.svg'
import redirect_load from "../../public/redirect_load.svg";

const HOME_URL = process.env.VUE_APP_HOME_URL || '';

const retry = async () => {
  await window.location.replace(props.redirect_url);
}

const cancel = async () => {
  await window.location.replace(HOME_URL)
}

const time = ref(10);

const interval = setInterval(() => --time.value, 1000);

watch(time, async () => {
  if (time.value < 1) {
    clearInterval(interval);
    await window.location.replace(props.redirect_url)
  }
})
</script>

<template>
  <div class="failed">
    <img :src="failed_icon" alt="failed_icon" class="failed-icon">

    <h2 class="failed__header">{{ 'Операція неуспішна!' }}</h2>

<!--    <p class="failed__des">-->
<!--      {{ 'Щось пішло не так. Спробуйте повторити платіж' }}-->
<!--    </p>-->

    <p class="failed__redirect-lbl">
      {{ 'Щось пішло не так. Вас буде перенаправлено через:' }}
    </p>

    <img :src="redirect_load" alt="redirect_load">
    <p class="timer">{{ time }}</p>

<!--    <div class="failed__buttons">-->
<!--      <button-->
<!--          class="button"-->
<!--          @click.prevent="retry"-->
<!--      >-->
<!--        {{ 'Повторити' }}-->
<!--      </button>-->

<!--      <button-->
<!--          class="button-alt"-->
<!--          @click.prevent="cancel"-->
<!--      >-->
<!--        {{ 'Відмінити' }}-->
<!--      </button>-->
<!--    </div>-->
  </div>
</template>

<style scoped>
.failed__redirect-lbl {
  text-align: center;
  color: #111111;
  font-size: 16px;
  font-weight: 400;
}

.timer {
  color: #000000;
  font-weight: 500;
  font-size: 12px;
  position: relative;
  bottom: 29px;
}

.failed {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 360px;
}

.failed-icon {
  width: 100px;
  height: 100px;
}

.failed__header {
  color: #111111;
  font-size: 24px;
  font-weight: 600;
  margin: 60px 0 20px;
}

.failed__des {
  color: #111111;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  margin-bottom: 40px;
}

.failed__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 928px) {
    flex-direction: column;
    gap: 20px;
  }
}

.button {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 172px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  color: #F8F8F8;
  background-color: #111111;
  margin-right: 24px;

  &:hover, &:active, &:focus {
    background-color: #2E2E2E;
  }

  @media (max-width: 928px) {
    width: 100%;
    margin: 0;
  }
}

.button-alt {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 172px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  color: #111111;
  border: 1px solid #111111;

  &:hover, &:active, &:focus {
    background-color: #111111;
    color: #F8F8F8;
  }

  @media (max-width: 928px) {
    width: 100%;
  }
}
</style>