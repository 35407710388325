<!-- eslint-disable -->
<script setup>
import success from '../../public/success.svg'
import redirect_load from '../../public/redirect_load.svg'
import {defineProps, ref, watch} from "vue";

const props = defineProps({
  redirect_url: {
    type: String,
    required: true
  },
  amount: {
    type: Number,
    required: true
  }
})

const time = ref(10);

const interval = setInterval(() => --time.value, 1000);

watch(time, async () => {
  if (time.value < 1) {
    clearInterval(interval);
    await window.location.replace(props.redirect_url)
  }
})
</script>

<template>
  <div class="success">
    <img :src="success" alt="success_icon" class="success-icon">

    <h2 class="success__header">
      {{ 'Дякуємо за оплату!' }}
    </h2>

    <p class="success__des">
      {{ 'Платіж на суму' }}
      &nbsp;<span class="success__des-extra">{{ `${(amount / 100).toFixed(2)} UAH` }}</span>&nbsp;
      {{ 'успішно оброблено' }}
    </p>

    <p class="success__redirect-lbl">
      {{ 'Вас буде перенаправлено через:' }}
    </p>

    <img :src="redirect_load" alt="redirect_load">
    <p class="timer">{{ time }}</p>
  </div>
</template>

<style scoped>
.success {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.success-icon {
  width: 100px;
  height: 100px;
}

.success__header {
  color: #111111;
  font-size: 24px;
  font-weight: 600;
  margin: 60px 0 20px;
}

.success__des {
  text-align: center;
  color: #111111;
  font-weight: 400;
  font-size: 16px;
  margin: 60px 0 20px;
}

.success__des-extra {
  color: #111111;
  font-weight: 700;
  font-size: 16px;
}

.success__redirect-lbl {
  color: #111111;
  font-size: 16px;
  font-weight: 400;
}

.timer {
  color: #000000;
  font-weight: 500;
  font-size: 12px;
  position: relative;
  bottom: 29px;
}
</style>