import api from "@/axiosInstance";

async function getTransaction (id) {
  if (!id) return null;

  try {
    const res = await api.get(`/txs/${id}/pay`);

    return res?.data
  } catch (e) {
    console.error(e)
  }
}

async function pay (id, form = {}) {
  if (!id) return null;

  try {
    const res = await api.post(`/txs/${id}/pay`, form);

    return res?.data?.payment_url;
  } catch (e) {
    console.error(e)
  }
}

async function cancel (id) {
  if (!id) return null;

  try {
    const res = await api.delete(`/txs/${id}/cancel`);

    return res?.data;
  } catch (e) {
    console.error(e)
  }
}

export { getTransaction, pay, cancel }