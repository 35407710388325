<script setup>
import { ref, computed, watch, defineProps, defineEmits } from 'vue';

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  inputType: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue', 'onInputValue']);

const inputValue = ref('');
const error = ref(null);

const fieldConfig = {
  first_name: {
    label: 'Ім`я',
    pattern: /^[a-zA-Z\u0400-\u04FF]{3,}$/,
    errorMessage: 'Ім`я має містити не менше 3 символів.',
    type: 'text',
    format: value => value.replace(/[^a-zA-Z\u0400-\u04FF]/g, ''),
  },
  last_name: {
    label: 'Прізвище',
    pattern: /^[a-zA-Z\u0400-\u04FF]{3,}$/,
    errorMessage: 'Прізвище має містити не менше 3 символів.',
    type: 'text',
    format: value => value.replace(/[^a-zA-Z\u0400-\u04FF]/g, ''),
  },
  dob: {
    label: 'Дата народження',
    pattern: /^\d{2}\.\d{2}\.\d{4}$/,
    errorMessage: 'Дата народження має бути у форматі dd.mm.yyyy.',
    type: 'text',
    format: value => value.replace(/[^0-9.]/g, ''),
  },
  email: {
    label: 'Email',
    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    errorMessage: 'Введіть валідну Email адресу.',
    type: 'email',
    format: value => value
  },
  phone: {
    label: 'Номер телефону',
    pattern: /^\+?\d{10,14}$/,
    errorMessage: 'Введіть валідний номер телнфону.',
    type: 'text',
    format: value => value.replace(/[^0-9+]/g, ''),
  },
  address: {
    label: 'Адреса',
    pattern: /^.{3,}$/,
    errorMessage: 'Адреса має містити не менше 3 символів.',
    type: 'text',
    format: value => value
  },
};

const config = computed(() => fieldConfig[props.inputType]);

const label = computed(() => config.value.label);
const inputElementType = computed(() => config.value.type);

const handleInput = (event) => {
  if (config.value.format) {
    event.target.value = config.value.format(event.target.value);
    inputValue.value = event.target.value;
    emit('update:modelValue', inputValue.value);
    validate();
    emit('onInputValue', props.inputType, inputValue.value, !error.value)
  }
};

const validate = () => {
  if (!config.value.pattern.test(inputValue.value)) {
    error.value = config.value.errorMessage;
  } else {
    error.value = null;
  }
};

watch(inputValue, validate);
</script>

<template>
  <div class="input-field-wrp">
    <label :for="inputType">{{ label }}</label>
    <input
        :id="inputType"
        :value="modelValue"
        @input="handleInput"
        :type="inputElementType"
        class="input-field"
    />
    <span v-if="error" class="error">{{ error }}</span>
  </div>
</template>

<style>
.input-field-wrp {
  max-width: 368px;
  display: flex;
  flex-direction: column;
}

.input-field {
  border-radius: 4px;
  height: 40px;
  border: 1px solid #111111;
  padding: 0 16px;
}

.error {
  color: red;
  font-size: 8px;
  position: absolute;
  margin-top: 62px;
}
</style>
