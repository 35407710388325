<!-- eslint-disable -->
<script setup>
import { getTransaction } from '@/api';
import Details from '@/components/Details.vue'
import Pending from '@/components/Pending.vue'
import Failed from '@/components/Failed.vue'
import Success from '@/components/Success.vue'
import {onMounted, ref, watch} from 'vue';

const fields = ref(null);
const id = ref(null);

const transaction = ref(null);

const STATUSES = {
  init: 'init',
  pending: 'pending',
  processing: 'processing',
  processed: 'processed',
  canceled: 'canceled',
  failed: 'failed'
}

const processTransactionData = async () => {
  transaction.value = await getTransaction(id.value);
  transactionStatus.value = transaction.value.status;
}

const transactionStatus = ref(null);

onMounted(async () => {
  if (window.location.pathname) {
    const uuid = window.location.pathname.split('/')[1];
    if (uuid) {
      id.value = uuid;

      transaction.value = await getTransaction(id.value);
      transactionStatus.value = transaction.value.status;

      if (transaction.value.status === STATUSES.init && transaction.value.required_details) {
        fields.value = transaction.value.required_details;
      }
    }
  }
})

let interval;

watch(transactionStatus, async () => {
  if (transactionStatus.value === STATUSES.processing) {
    interval = setInterval(processTransactionData, 1000)
  } else if (transactionStatus.value !== STATUSES.processing && interval) {
    clearInterval(interval)
  }
})
</script>

<template>
  <div class="app">
    <div v-if="transaction" class="app__window">
      <Details
          v-if="transaction.status === STATUSES.init || transaction.status === STATUSES.pending"
          :amount="transaction.amount"
          :status="transaction.status"
          :fields="fields"
          :id="transaction.uuid"
          @on-status-changed="processTransactionData"
      />
      <Pending v-else-if="transaction.status === STATUSES.processing"/>
      <Failed v-else-if="transaction.status === STATUSES.failed || transaction.status === STATUSES.canceled" :redirect_url="transaction.failed_redirect_url"/>
      <Success
          v-else-if="transaction.status === STATUSES.processed"
          :amount="transaction.amount"
          :redirect_url="transaction.success_redirect_url"
      />
    </div>
  </div>
</template>

<style>
.app {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.app__window {
  background: #FFFFFF;
  border: 1px solid #D0D0D0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 80px;
  border-radius: 10px;

  @media (max-width: 928px) {
    padding: 32px 20px;
  }
}
</style>
